import { useMemo } from "react";
import { useClearRefinements, useHits, useInstantSearch, usePagination, useSearchBox } from "react-instantsearch";
import { Flex, GridV2 } from "sparta";
import { useExport } from "~/context/ExportContext"; // Adjust the import path as necessary
import { NoResults } from "../NoResults";
import { SkeletonLoader } from "../SkeletonLoader";
import type { IPostHit } from "../types";
import { Pagination } from "./Pagination";
import { PostHit } from "./PostHit";

export function PostHits() {
  const { refine: clearRefinements } = useClearRefinements();
  const { refine: searchBoxRefine } = useSearchBox();
  const { selectedIds, addSelectedId, removeSelectedId } = useExport();
  const { status } = useInstantSearch();
  const { hits } = useHits<IPostHit>();
  const { pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine } = usePagination();
  const handleSelect = (id: string) => {
    selectedIds.includes(id) ? removeSelectedId(id) : addSelectedId(id);
  };
  const noResults = useMemo(() => status === "idle" && hits?.length === 0, [status, hits]);

  if (status === "stalled") return <SkeletonLoader />;
  if (noResults)
    return (
      <NoResults
        onClear={() => {
          clearRefinements();
          searchBoxRefine("");
        }}
      />
    );
  return (
    <>
      <GridV2 columns={{ initial: "1", sm: "2", md: "3" }} gap="2">
        {hits.map((hit) => (
          <PostHit
            key={hit.slug}
            hit={hit}
            selected={selectedIds.includes(hit.id)}
            onSelect={() => handleSelect(hit.id)}
          />
        ))}
      </GridV2>
      <Flex width="100%" align="end">
        <Pagination
          pages={pages}
          currentRefinement={currentRefinement}
          nbPages={nbPages}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          refine={refine}
        />
      </Flex>
    </>
  );
}
