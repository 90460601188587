import { Button, Flex, Heading, Text } from "sparta";

interface NoResultsProps {
  onClear: () => void;
}

export function NoResults({ onClear }: NoResultsProps) {
  return (
    <div
      style={{
        color: "#0E214B",
        textAlign: "center",
        position: "relative", //Make the positioning relative
      }}
    >
      <Flex
        align="center"
        justify="center"
        mt="9"
        style={{
          zIndex: -1, // Set a lower z-index to place it behind the text
        }}
      >
        <svg width="500" height="361" viewBox="0 0 500 361" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_643_23763)">
            <path
              d="M37.7099 82.5901C34.0399 83.1701 31.5299 86.6101 32.1099 90.2901L59.8799 266.75C60.4599 270.42 63.8999 272.93 67.5799 272.35L196.09 252.12C199.76 251.54 202.27 248.1 201.69 244.42L178.49 97.0301L135.81 67.1401L37.7099 82.5801V82.5901Z"
              fill="#F5F5F5"
            />
            <path
              d="M145.79 102.18L178.5 97.0301L135.82 67.1401L140.76 98.5101C141.14 100.91 143.39 102.55 145.79 102.17V102.18Z"
              fill="#D6D6D6"
            />
            <path
              d="M135.82 67.1499L140.76 98.5199C141.14 100.92 143.39 102.56 145.79 102.18L178.5 97.0299"
              stroke="#262626"
              strokeWidth="0.27"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M108.67 186.78C107.96 182.28 107.74 178.61 107.99 175.76C108.24 172.91 109.18 170.18 110.8 167.59C111.73 166.04 112.84 164.65 114.13 163.43C115.42 162.21 116.59 160.95 117.64 159.66C118.68 158.36 119.52 156.96 120.14 155.46C120.76 153.96 120.9 152.1 120.55 149.89C119.96 146.16 118.52 143.71 116.23 142.55C113.94 141.39 111.38 141.03 108.56 141.47C104.52 142.11 101.92 143.67 100.75 146.16C99.58 148.65 99.1 151.83 99.32 155.7L84.58 158.02C83.99 154.29 84.07 150.8 84.81 147.56C85.55 144.32 86.87 141.46 88.79 138.97C90.7 136.48 93.15 134.42 96.13 132.78C99.11 131.14 102.51 130.02 106.32 129.42C114.55 128.12 121.31 128.78 126.6 131.38C131.89 133.98 135.13 139.06 136.32 146.6C136.7 149.04 136.79 151.21 136.58 153.12C136.37 155.03 135.89 156.8 135.13 158.44C134.37 160.08 133.37 161.67 132.13 163.19C130.89 164.71 129.46 166.3 127.84 167.96C126.37 169.52 125.2 170.93 124.35 172.2C123.5 173.47 122.9 174.75 122.56 176.05C122.22 177.35 122.08 178.69 122.13 180.04C122.19 181.4 122.35 182.92 122.61 184.59L108.66 186.78H108.67ZM111.69 208.91L109.33 193.94L124.76 191.51L127.12 206.48L111.69 208.91Z"
              fill="#D6D6D6"
            />
            <path
              d="M184.16 195.64C201.17 212.36 220.96 234.92 239.34 264.08C252.13 284.36 261.61 303.66 268.69 320.64C252.38 327.98 236.08 335.32 219.77 342.65L177.85 199.99L184.15 195.64H184.16Z"
              fill="#0E214B"
            />
            <path
              d="M153.27 184.1L184.63 193.9C193.22 207.53 198.19 219.6 201.1 227.99C214.67 267.09 210.32 302.87 209.09 312.98C209.09 312.98 209.07 313.15 205.12 342.64H61.7199C61.2499 331.95 61.3399 317.17 63.8599 299.84C66.8499 279.28 69.6299 260.2 82.0699 239.24C87.1299 230.72 104.72 203.44 138.46 189.17C144.48 186.62 149.68 185.05 153.27 184.1Z"
              fill="#0E214B"
            />
            <path
              d="M79.5 243.8C96.96 249.4 119.18 254.9 145.31 257.57C169.64 260.06 191.1 259.47 208.48 257.84C208.98 261.94 209.48 266.04 209.98 270.14C191.41 271.65 169.33 272.11 144.62 269.95C116.94 267.53 92.97 262.44 73.49 256.97C75.49 252.58 77.5 248.19 79.5 243.8Z"
              fill="#FF7129"
            />
            <path opacity="0.5" d="M214.06 232.83L221.16 234.42L195.3 342.65H180.65L214.06 232.83Z" fill="#E8A600" />
            <path
              d="M156.04 321.92L162.12 320.02L176.06 327.23L179.58 318.7L161.88 311.9L154.66 315.28L156.04 321.92Z"
              fill="#E8A600"
            />
            <path
              opacity="0.5"
              d="M61.49 326.44C75.7 329.99 92.7 333.17 112.01 334.72C128.94 336.07 144.25 335.89 157.48 335V318.7C125.92 315.17 94.37 311.65 62.81 308.12L61.49 326.44Z"
              fill="#E8A600"
            />
            <path
              d="M165.93 206.64C157.06 205.12 151.11 196.7 152.63 187.83L158.55 153.28L190.66 158.78L184.74 193.33C183.22 202.2 174.8 208.15 165.93 206.63V206.64Z"
              fill="#F7A99C"
            />
            <path
              d="M186.39 183.66L167.37 169.26C167.26 171.11 166.97 179.79 173.39 186.95C177.49 191.52 182.35 193.25 184.61 193.9L186.38 183.66H186.39Z"
              fill="#FF7769"
            />
            <path
              d="M159.65 142.45C159.65 141.91 158.4 140.88 158.03 140.54C157.4 139.96 156.69 139.46 155.93 139.06C154.45 138.28 152.75 137.86 151.08 137.95C147.75 138.12 144.44 140.31 143.34 143.51C142.7 145.37 142.79 147.42 143.3 149.32C144.07 152.18 145.77 154.76 148.04 156.66C149.92 158.23 152.18 159.33 154.56 159.9C155.04 160.01 159.99 160.76 159.98 160.57C159.87 154.53 159.76 148.5 159.65 142.46V142.45Z"
              fill="#F7A99C"
            />
            <path
              d="M155.19 142.01C155.12 144.65 155 158.64 165.56 170.39C167.51 172.55 180.44 186.94 195.17 183.61C214.1 179.33 217.25 150.03 217.4 148.44C217.91 143.06 219.41 127.23 207.94 116.63C198.58 107.98 186.7 108.47 185.01 108.57C183.23 108.67 171.86 109.51 163.32 119.02C155.57 127.65 155.3 137.7 155.18 142.02L155.19 142.01Z"
              fill="#F7A99C"
            />
            <path
              d="M207.4 146.7C207.29 149.01 208.74 150.96 210.65 151.05C212.56 151.14 214.2 149.34 214.31 147.03C214.42 144.72 212.97 142.77 211.06 142.68C209.15 142.59 207.51 144.39 207.4 146.7Z"
              fill="#262626"
            />
            <path
              d="M200.65 149L203.03 153.77C203.47 154.65 203.06 155.71 202.15 156.07L198.98 157.31"
              stroke="#262626"
              strokeWidth="1.7"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M177.87 155.13C177.74 157.72 179.74 159.93 182.34 160.05C184.94 160.17 187.14 158.18 187.26 155.58C187.39 152.99 185.39 150.78 182.79 150.66C180.2 150.53 177.99 152.53 177.87 155.13Z"
              fill="#FF7769"
            />
            <path
              d="M155.46 153.67C155.56 153.16 156.21 149.73 153.88 146.76C152.06 144.43 149.56 143.86 148.87 143.73"
              stroke="#262626"
              strokeWidth="1.7"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M154.03 147.48C153.88 147.43 152.07 146.85 150.53 148.02C149.26 148.99 149.11 150.43 149.09 150.67"
              stroke="#262626"
              strokeWidth="1.7"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M213.97 153.92C214.87 153.96 215.68 154.27 216.38 154.74C215.85 157.21 215.09 160.18 213.98 163.29C213.83 163.29 213.67 163.32 213.52 163.31C210.93 163.18 208.93 160.98 209.05 158.39C209.18 155.8 211.38 153.8 213.97 153.92Z"
              fill="#FF7769"
            />
            <path
              d="M188.95 141.73L192.87 146.17L187.26 148.14"
              stroke="#262626"
              strokeWidth="1.7"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M187.26 166.78L201.35 168.39C201.31 168.1 200.69 163.94 197.35 162.43C194.07 160.95 189.45 162.59 187.26 166.79V166.78Z"
              fill="white"
            />
            <path
              d="M155.34 138.77C156.12 139.07 157.95 139.9 159.4 141.86C160.74 143.66 161.06 145.48 161.16 146.31C175.77 144.92 184.21 140.82 189.29 137.12C189.29 137.12 189.66 136.85 200.37 128.71C202.1 130.57 204.8 133.07 208.62 135.21C212.09 137.15 215.3 138.14 217.65 138.67C225.33 136.88 230.68 131.15 230.86 125.16C231.05 118.85 225.44 114.07 221.46 112.09C214.97 108.85 211.98 112.63 202.45 110.14C194.77 108.13 195.06 105.24 187.86 103.5C185.68 102.97 176.65 100.78 167.57 105.56C159.91 109.59 153.84 117.73 153.24 127.11C152.91 132.25 154.3 136.39 155.33 138.77H155.34Z"
              fill="#262626"
            />
            <path
              d="M153.27 184.1C140.38 187.04 116.28 194.46 93.83 214.54C93.83 214.54 71.2 234.78 55.77 272.32C54.4 275.65 52.87 279.16 51.18 286.54C49.87 292.26 49.28 297.54 49.09 302.14C48.95 305.58 51.07 308.72 54.31 309.89C57.16 310.92 60.43 312.07 64.06 313.29C70.79 315.53 85.71 321.64 102.61 323.99C137.86 328.88 157.42 330.1 157.42 330.1L159.98 269.26L113.39 263.36L153.27 184.09V184.1Z"
              fill="#0E214B"
            />
            <path
              d="M190.91 215.39C196.76 235.86 200.39 252.99 202.68 265.48"
              stroke="#262626"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path opacity="0.5" d="M129.97 211.72L103.83 270.6H119.65L129.97 211.72Z" fill="#E8A600" />
            <path
              d="M217.769 233.886L203.646 229.884L172.829 338.643L186.953 342.645L217.769 233.886Z"
              fill="#4450E4"
            />
            <path
              d="M158.96 283.6C158.96 283.6 185.45 280.96 188.27 281.83C191.09 282.7 200.76 288.17 202.13 289.73C203.5 291.29 203.98 292.75 203.79 294.22C203.59 295.68 201.64 297.34 201.64 297.34C203.17 299.72 203.22 301.35 202.9 302.46C202.32 304.43 200.01 306.49 199.99 306.51C200.16 307.12 200.35 308 200.4 309.06C200.45 310.07 200.55 312.11 199.54 313.97C198.05 316.73 194.98 317.52 194.3 317.67C194.3 317.67 195.49 319.88 193.93 321.73C192.37 323.58 187.68 325.93 182.12 325.15C176.56 324.37 162.12 317.25 162.12 317.25L157.48 318.7L158.96 283.59V283.6Z"
              fill="#F7A99C"
            />
            <path
              opacity="0.5"
              d="M231.32 232.83C266.915 232.83 295.77 203.975 295.77 168.38C295.77 132.785 266.915 103.93 231.32 103.93C195.725 103.93 166.87 132.785 166.87 168.38C166.87 203.975 195.725 232.83 231.32 232.83Z"
              fill="#CDEBF5"
            />
            <path
              d="M231.32 95.5498C191.1 95.5498 158.49 128.16 158.49 168.38C158.49 208.6 191.1 241.21 231.32 241.21C271.54 241.21 304.15 208.6 304.15 168.38C304.15 128.16 271.54 95.5498 231.32 95.5498ZM231.32 229.32C197.66 229.32 170.38 202.04 170.38 168.38C170.38 134.72 197.66 107.44 231.32 107.44C264.98 107.44 292.26 134.72 292.26 168.38C292.26 202.04 264.98 229.32 231.32 229.32Z"
              fill="#4450E4"
            />
            <path
              d="M201.64 297.35C201.64 297.35 191.88 295.53 186.95 293.24C182.02 290.95 180.19 289.69 180.19 289.69"
              stroke="#262626"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M199.98 306.52C199.98 306.52 188.67 305.39 184.09 302.75C179.5 300.11 177.86 298.39 177.86 298.39"
              stroke="#262626"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M196.25 316.99C191.41 316.8 187.77 315.72 185.23 314.68C181 312.94 175.71 309.64 175.71 309.64"
              stroke="#262626"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M158.96 283.6L157.48 318.7L162.12 317.25L160.63 283.43L158.96 283.6Z" fill="#FF7769" />
            <path
              d="M128.14 215.39C128.14 215.39 103.84 268.82 104.52 270.19C105.21 271.57 159.97 269.27 159.97 269.27L157.41 330.11C157.41 330.11 114.65 327.41 97.73 323.21C80.81 319.01 61.49 312.43 61.49 312.43"
              stroke="#262626"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M234.64 125.23C234.26 126.07 234.2 127.05 234.55 127.98C235.25 129.85 237.34 130.8 239.21 130.1C242.61 128.82 246.29 128.29 249.86 128.54C253.85 128.83 256.9 130.03 258.36 130.72C260.1 131.54 263.71 133.24 266.41 136.95C270.16 142.12 269.85 148.16 269.54 150.52C269.28 152.5 270.68 154.32 272.66 154.58C274.64 154.84 276.46 153.44 276.72 151.46C277.24 147.46 277.36 139.7 272.27 132.69C268.48 127.47 263.55 125.16 261.44 124.17C259.1 123.07 255.27 121.68 250.38 121.33C245.78 121 241.03 121.69 236.66 123.33C235.72 123.68 235.01 124.39 234.63 125.24L234.64 125.23Z"
              fill="white"
            />
            <path
              d="M107.88 209.96C99.0399 217.82 87.6899 229.55 77.3299 245.81C66.6899 262.5 60.7999 278.16 57.4299 289.68"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M163.22 330.11H279.09V342.64H170.86C166.64 342.64 163.22 339.22 163.22 335V330.11Z"
              fill="#2A83C6"
            />
            <path
              d="M359.83 342.65H241.75C237.11 342.65 233.81 338.69 234.38 333.8L243.3 256.8C243.87 251.91 248.09 247.95 252.72 247.95H370.8C375.44 247.95 378.74 251.91 378.17 256.8L369.25 333.8C368.68 338.69 364.46 342.65 359.83 342.65Z"
              fill="#2A83C6"
            />
            <path
              d="M371.23 342.65H253.15C248.51 342.65 245.21 338.69 245.78 333.8L254.7 256.8C255.27 251.91 259.49 247.95 264.12 247.95H382.2C386.84 247.95 390.14 251.91 389.57 256.8L380.65 333.8C380.08 338.69 375.86 342.65 371.23 342.65Z"
              fill="#3897E9"
            />
            <path
              d="M389.58 261.23L380.66 334.25C380.66 334.25 381.06 342.65 367.3 342.65H290.03C298.35 342.6 327.48 341.4 354.23 319.49C380.93 297.63 387.88 269.38 389.58 261.23Z"
              fill="#5FBBFF"
            />
            <path
              d="M387.12 270.6C384.45 279.26 377.95 296.2 362.38 312.03C347.02 327.64 330.45 334.43 321.87 337.28"
              stroke="#262626"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M317.71 303H313.58L316.21 286.27H320.34L317.71 303Z" fill="#0E214B" />
            <path d="M323.13 303H319L320.81 291.46H324.94L323.13 303Z" fill="#0E214B" />
            <path
              d="M202.36 105.84C196.4 109.2 181.36 118.73 171.49 137.94C161.65 157.1 162.64 174.82 163.37 181.63"
              stroke="#262626"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M209.64 308.71L207.32 292.34" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M438.55 166.27H283.85C280.304 166.27 277.43 169.144 277.43 172.69V285.95C277.43 289.496 280.304 292.37 283.85 292.37H438.55C442.096 292.37 444.97 289.496 444.97 285.95V172.69C444.97 169.144 442.096 166.27 438.55 166.27Z"
              fill="#F5F5F5"
            />
            <path
              opacity="0.5"
              d="M444.97 180.86V172.69C444.97 169.14 442.1 166.27 438.55 166.27H283.85C280.3 166.27 277.43 169.14 277.43 172.69V180.86H444.97Z"
              fill="#D6D6D6"
            />
            <path
              d="M286.14 174.33C286.14 175.43 285.25 176.32 284.15 176.32C283.05 176.32 282.16 175.43 282.16 174.33C282.16 173.23 283.05 172.34 284.15 172.34C285.25 172.34 286.14 173.23 286.14 174.33Z"
              fill="#F5F5F5"
            />
            <path
              d="M292.42 174.33C292.42 175.43 291.53 176.32 290.43 176.32C289.33 176.32 288.44 175.43 288.44 174.33C288.44 173.23 289.33 172.34 290.43 172.34C291.53 172.34 292.42 173.23 292.42 174.33Z"
              fill="#F5F5F5"
            />
            <path
              d="M298.84 174.33C298.84 175.43 297.95 176.32 296.85 176.32C295.75 176.32 294.86 175.43 294.86 174.33C294.86 173.23 295.75 172.34 296.85 172.34C297.95 172.34 298.84 173.23 298.84 174.33Z"
              fill="#F5F5F5"
            />
            <path
              opacity="0.5"
              d="M379.31 210.82V202.64C379.31 199.96 377.14 197.78 374.45 197.78H326.99C324.31 197.78 322.13 199.95 322.13 202.64V265.28C322.13 267.96 324.3 270.14 326.99 270.14H402.88C405.56 270.14 407.74 267.97 407.74 265.28V215.67C407.74 212.99 405.57 210.81 402.88 210.81H379.3L379.31 210.82Z"
              fill="#D6D6D6"
            />
            <path
              d="M374.5 222.24L364.94 231.8L355.38 222.24L349.61 228.01L359.17 237.57L349.61 247.13L355.38 252.9L364.94 243.34L374.5 252.9L380.27 247.13L370.71 237.57L380.27 228.01L374.5 222.24Z"
              fill="#D6D6D6"
            />
            <path
              d="M283.85 166.27H438.55C442.1 166.27 444.97 169.14 444.97 172.69V285.95"
              stroke="#262626"
              strokeWidth="0.27"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M430.39 195.44C429.65 189.59 429.51 184.81 429.95 181.13C430.39 177.45 431.72 173.95 433.93 170.65C435.19 168.68 436.69 166.93 438.41 165.4C440.13 163.87 441.7 162.29 443.11 160.65C444.51 159.01 445.65 157.23 446.52 155.31C447.39 153.39 447.64 150.99 447.28 148.11C446.67 143.25 444.9 140.02 441.98 138.42C439.06 136.82 435.76 136.25 432.09 136.71C426.83 137.37 423.39 139.29 421.78 142.47C420.16 145.65 419.42 149.75 419.54 154.77L400.34 157.18C399.73 152.32 399.97 147.8 401.06 143.63C402.15 139.46 403.98 135.8 406.56 132.66C409.14 129.52 412.39 126.94 416.33 124.93C420.26 122.92 424.71 121.61 429.67 120.99C440.39 119.64 449.12 120.77 455.87 124.35C462.62 127.94 466.61 134.64 467.85 144.47C468.25 147.65 468.27 150.46 467.93 152.93C467.58 155.39 466.89 157.67 465.84 159.77C464.79 161.87 463.43 163.88 461.76 165.8C460.09 167.73 458.17 169.73 456.01 171.81C454.04 173.77 452.47 175.56 451.31 177.16C450.15 178.77 449.32 180.41 448.83 182.08C448.33 183.76 448.1 185.48 448.12 187.23C448.14 188.99 448.29 190.97 448.56 193.15L430.4 195.43L430.39 195.44ZM433.4 224.24L430.95 204.74L451.04 202.22L453.49 221.72L433.4 224.24Z"
              fill="#FF7129"
            />
            <path
              d="M383.438 99.9168C403.523 79.8321 403.523 47.2685 383.438 27.1838C363.353 7.09914 330.79 7.09913 310.705 27.1838C290.62 47.2685 290.62 79.8321 310.705 99.9168C330.79 120.001 363.353 120.001 383.438 99.9168Z"
              fill="#F5F5F5"
            />
            <path
              opacity="0.5"
              d="M376.579 93.0579C392.876 76.7613 392.876 50.3393 376.579 34.0427C360.283 17.7462 333.861 17.7461 317.564 34.0427C301.267 50.3393 301.267 76.7613 317.564 93.0579C333.861 109.354 360.283 109.354 376.579 93.0579Z"
              fill="#D6D6D6"
            />
            <path d="M348.45 34.1802H345.7V68.1402H348.45V34.1802Z" fill="#D6D6D6" />
            <path d="M347.078 65.6077L345.703 67.9893L364.755 78.9893L366.13 76.6077L347.078 65.6077Z" fill="#D6D6D6" />
          </g>
          <defs>
            <clipPath id="clip0_643_23763">
              <rect width="500" height="361" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Flex>
      <Heading as="h3" size="8">
        No results
      </Heading>
      <Text size="3" color="gray">
        We've found no results for the current search, please try again with different parameters.
      </Text>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button onClick={onClear} size="3">
          Return Home
        </Button>
      </div>
    </div>
  );
}
