export default function useCatchLog(caught?: any, payload?: any) {
  const clientSideLog = (caught: any, payload?: any) => {
    // create search params from payload
    const params = new URLSearchParams({
      status: caught.status,
      message: caught.data,
      ...payload,
    });

    fetch(`${ENV.HOME_URI}/api/event-log?${params.toString()}`).catch(() =>
      console.error("Failed to submit catch log"),
    );
  };

  // submit catch log
  if (caught) {
    clientSideLog(caught, payload);
  }

  return clientSideLog;
}
