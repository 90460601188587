import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import PostExporter from "~/utils/export/PostExporter";

interface ExportContextProps {
  selectedIds: string[];
  addSelectedId: (id: string) => void;
  removeSelectedId: (id: string) => void;
  clearSelectedIds: () => void;
  handleExport: () => void;
}
const ExportContext = createContext<ExportContextProps | undefined>(undefined);
export const ExportProvider = ({ children, initialUrl }: { children: ReactNode; initialUrl: string }) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const addSelectedId = (id: string) => setSelectedIds((prevIds) => [...prevIds, id]);
  const removeSelectedId = (id: string) => setSelectedIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
  const clearSelectedIds = () => setSelectedIds([]);
  useEffect(() => {
    const newUrl = new URL(initialUrl);
    newUrl.searchParams.delete("exportIds"); // Clear existing
    selectedIds.forEach((id) => newUrl.searchParams.append("exportIds", id));

    // Ensure the protocol matches the current page's protocol
    newUrl.protocol = window.location.protocol;

    // This is a soft update without navigation
    window.history.replaceState({}, "", newUrl.toString());
  }, [selectedIds, initialUrl]);
  const handleExport = () => {
    if (selectedIds.length > 0) {
      const postExporter = new PostExporter(window.location.href);
      postExporter.exportIfApplicable(); // This will work because IDs are now in the URL
    }
  };
  useEffect(() => {
    handleExport();
  }, [selectedIds]);
  return (
    <ExportContext.Provider value={{ selectedIds, addSelectedId, removeSelectedId, clearSelectedIds, handleExport }}>
      {children}
    </ExportContext.Provider>
  );
};
export const useExport = () => {
  const context = useContext(ExportContext);
  if (!context) {
    throw new Error("useExport must be used within an ExportProvider");
  }
  return context;
};
